<script>
import Confirmation from '@/components/Confirmation.vue'
import gql from 'graphql-tag';
import { mapGetters } from 'vuex'

import ListModule from '/src/router/views/farms/module/List.vue'

// import { geneticPatternsDataType, categoriesAnimalDataType, categoriesComplementaryDataType } from '@/utils/enums'
// import Swal from 'sweetalert2';

export default {
  components: {
    Confirmation,
    ListModule,
  },
  
  props: ['dialog'],

  data() {
    return {
      id: '',
      form: {},
      desactivatedFarm: false
    };
  },

 
  computed: {
    ...mapGetters('auth', ['isManager'])
  },

  async created() {
    this.form = await this.fetch();
  },

  methods: {
    async fetch() {
      try {
        this.id = localStorage.getItem('lastFarm')
        const result = await this.$apollo.query({
          query: gql`
            query farms($id: uuid!) {
              farm: farms_by_pk(id: $id) {
                name
                address
                city
                state
                country
                main_species_produced
                customer_id
                desactivated
                modules(order_by: {code: asc}) {
                  id
                  name
                  code
                  desired_use
                  irrigation
                  fertilized
                  type_use
                  description
                  farm_id
                  factor_adjust_fdn
                  factor_adjust_pb
                  factor_adjust_em
                  desactivated
                  temporary
                  created_at
                  start_date
                  paddocks_aggregate {
                    aggregate {
                      sum {
                        area
                      }
                    }
                  }
                  paddocks(order_by: {code: asc}) {
                    id
                    code
                    name
                    description
                    area
                    irrigation
                    fertilized
                    type_use
                    module_id
                    customer_id
                    desactivated
                    ref_module_id
                    paddock_cultivars{
                      customer_id
                      cultivar_id
                      percentage
                    }
                  }
                }
              }
            }
          `,
          variables: {
            id: this.id,
          },
          fetchPolicy: 'no-cache',
        });
        const farm = result.data.farm
        this.desactivatedFarm = farm.desactivated
        if (farm && farm.modules && farm.modules.length > 0) {
          for (let index = 0; index < farm.modules.length; index++) {
            const paddocks = await this.$apollo.query({
            query: gql`
              query paddocks($module_id: uuid! ) {
                paddocks(
                  where: { 
                    _or: [
                      { ref_module_id: {_eq: $module_id } }, 
                      { module_id: {_eq: $module_id} }
                    ]
                  }  order_by: { created_at: asc }
                ) {
                    id
                    code
                    name
                    description
                    area
                    irrigation
                    fertilized
                    type_use
                    module_id
                    module{
                      name
                    }
                    customer_id
                    desactivated
                    ref_module_id
                    ref_module{
                      id
                      name
                    }
                    paddock_cultivars{
                      customer_id
                      cultivar_id
                      percentage
                    }
                  }
              }
            `,
            variables: {
              module_id: farm.modules[index].id,
            },
            fetchPolicy: 'no-cache',
            });
          farm.modules[index].paddocks = paddocks.data.paddocks
          }
        }
        return result.data.farm;
      } catch (e) {
        console.error(e);
      }
    },

    closeDialog() {
        this.$emit('closeDialog');
    },
  }
  
}
</script>

<template>
    <div>
      <v-dialog v-model="dialog" @click:outside="closeDialog">
        <v-card>
            <v-card-title>
                <span>Módulos</span>
            </v-card-title>
            <v-card-text>
                <list-module
                    v-model="form.modules"
                    :farm-id="id"
                    :farm-name="form.name"
                    :customer-id="form.customer_id"
                    :customer-name="form.customerName"
                    :desactivated="desactivatedFarm"
                />
            </v-card-text>
        </v-card>
     </v-dialog>
    </div>
</template>

